import Constants from "../../utils/Constants";
import { Popover } from "element-ui";
import _ from "lodash";

export default {
    name: 'home',
    metaInfo: {
        title: '鹿老师说外语 - 小语种学习神器',
        meta: [{
            name: 'keywords',
            content: 'LingoDeer,鹿老师说外语,小语种,日语,韩语,语言,入门,自学'
        }, {
            name: 'description',
            content: '鹿老师说外语App（LingoDeer）是一款由专业语言教师为初学者量身定制的语言学习应用，汇集英语、日语、韩语、法语、德语、西班牙语、葡萄牙语等主流外语的原创课程。该应用采取游戏闯关的学习模式，让学外语更轻松。'
        }]
    },
    data() {
        return {
            landPagePic: Constants.landPagePic,
            scanCodeAdActiveClass: "",
            scanCodeAdActiveClassFoldClass: "",
            popRectActiveClass: ""
        }
    },
    components: {
        "el-popover": Popover
    },
    mounted() {
        this._refreshPage();

        //ScanCodeAd
        this.bouncedScrollEvent = _.debounce(this._judgeShowOrHideScanCodeAd, 100);
    },
    beforeDestroy() {
        $(".home").unbind('scroll')
    },
    watch: {
        $route: function () {
            //PopRect
            this._judgeShowOrHidePopRect();

            //ScanCodeAd
            this._judgeShowOrHideScanCodeAd();
        }
    },
    methods: {
        _refreshPage: function () {
            this._judgeShowOrHidePopRect();
            this._judgeShowOrHideScanCodeAd();

            setTimeout(() => {
                this._bindScrollEvent();
            }, 1000);
        },

        _bindScrollEvent: function () {
            $(".home").scroll(() => {
                this.bouncedScrollEvent();
            });
        },

        //ScanCodeAd
        _judgeShowOrHideScanCodeAd: function () {
            if (window.innerWidth < 768) {
                this.scanCodeAdActiveClass = "";
                clearTimeout(this.scrollTimeOut);
                return;
            }

            clearTimeout(this.scrollTimeOut);
            this.scrollTimeOut = setTimeout(this._isScrollEnd, 800);

            this.homeScrollTop1 = $(".home").scrollTop();
            if (this.$route.path === "/") {
                let panel1Height = $(".panel1").height() * 0.9;
                if (this.homeScrollTop1 > panel1Height) {
                    if (this.scanCodeAdActiveClass === 'active') {
                        this.scanCodeAdActiveClassFoldClass = "isFold";
                    } else {
                        this.scanCodeAdActiveClass = "active";
                    }
                } else {
                    this.scanCodeAdActiveClass = "";
                    this.scanCodeAdActiveClassFoldClass = "";
                }
            } else {
                if (this.scanCodeAdActiveClass === 'active') {
                    this.scanCodeAdActiveClassFoldClass = "isFold";
                } else {
                    this.scanCodeAdActiveClass = 'active';
                    this.scanCodeAdActiveClassFoldClass = "";
                }
            }
        },

        _isScrollEnd: function () {
            this.homeScrollTop2 = $(".home").scrollTop();
            if (this.homeScrollTop1 === this.homeScrollTop2) {
                if (this.$route.path !== "/") {
                    this.scanCodeAdActiveClassFoldClass = "";
                }
            }
        },

        //PopRect
        _judgeShowOrHidePopRect: function () {
            if (window.innerWidth < 768) {
                this.popRectActiveClass = "active";
                return;
            }

            if (this.$route.path !== "/") {
                this.popRectActiveClass = "active";
            } else {
                this.popRectActiveClass = "";
            }
        },

        backToHome: function () {
            if (this.$route.path !== "/") {
                this.$router.push({ path: "/" });
            }
        }
    }
};
